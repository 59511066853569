$color-bg-dark: rgb(22, 22, 22);
$color-bg: rgb(24, 24, 27);
$color-bg-light: rgb(31, 31, 35);
$color-fg: rgb(14, 14, 16);

$dark: #343a40; // gray-800

// Bootstrap 4 font stack
$font-family-sans-serif:
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  "Liberation Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

$body-bg: $color-bg-dark;
$text-color: #eee;

$list-group-bg: inherit;
$list-group-color: #{$text-color};

$navbar-height: 48px;
$navbar-padding-vertical: 4px;

$input-bg: $color-bg-dark;
$input-color-placeholder: darken($text-color, 50%);
$input-color: $text-color;
$input-group-addon-bg: $color-bg;

$popover-bg: $color-bg-light;
$popover-header-bg: darken($popover-bg, 5%);
$popover-header-color: $text-color;
$popover-body-color: $text-color;
$popover-border-color: lighten($color-bg-light, 5%);

$toast-background-color: $color-bg-light;
$toast-color: $text-color;
$toast-border-color: lighten($color-bg-light, 20%);
$toast-header-color: $text-color;
$toast-header-background-color: darken($popover-bg, 5%);
$toast-header-border-color: lighten($color-bg-light, 5%);

$border-color: hsla(0, 0%, 100%, 0.1);

$pagination-bg: $color-bg-light;
$pagination-border-color: $border-color;
$pagination-disabled-bg: $color-bg-dark;
$pagination-disabled-border-color: $border-color;
$pagination-hover-bg: $color-bg-dark;
$pagination-hover-border-color: $border-color;

$modal-content-color: $text-color;
$modal-content-bg: $color-bg-dark;

@import "~bootstrap/scss/bootstrap";
@import "~react-custom-scroll/dist/customScroll";

.toast-container {
  z-index: 100;

  & .toast-header .btn-close {
    margin-left: auto;
  }
}

.navbar {
  min-height: $navbar-height;
}

.flex-1-0-0 {
  flex: 1 0 0;
}

.flex-1-1-0 {
  flex: 1 1 0;
}

.flex-0-1-0 {
  flex: 0 1 0;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.stretched-link::after {
  z-index: 0;
}

a {
  color: #629cf2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.progress {
  background-color: inherit;
}

.progress-bar.bg-warning {
  color: black;
}

.popover input.form-control {
  background-color: #{$input-bg};
  color: #{$input-color};
}

.form-control, .input-group-text, .input-group > select {
  border-color: #{$border-color};
}

.list-group-item-action:not(.active):hover {
	color: #{$text-color};
  background-color: #{$color-bg-light};
}

.list-group-item-action:active, .list-group-item-action:not(.active):focus {
	color: #{$text-color};
  background-color: #{$color-bg-light};
}

.dropdown-item:hover, .dropdown-item:focus {
	color: #{$text-color};
	background-color: #{$color-bg-light};
}

.dropdown-item.active, .dropdown-item:active {
	color: #{$text-color};
	text-decoration: none;
	background-color: #005ec2;
}

.rcs-custom-scrollbar {
  z-index: 1000 !important;
}

.pagination {
  & .page-link {
    color: $text-color;
    display: flex;
    justify-content: center;
  }
}

.navbar {
  padding: #{$navbar-padding-vertical} 1rem;
  background-color: #{$color-bg};
  box-shadow: rgba(0, 0, 0, 0.9) 0px 1px 2px 0px;

  & a:hover {
    text-decoration: none;
  }

  & .navbar-collapse .rbt {
    @media(max-width: 767px) {
      // mimic .nav-link and align .rbt-input-hint with other links
      padding: 0.5rem calc(1rem - 12px);

      width: 100%;

      & .form-control {
        width: 100% !important;
      }
    }
  }
}

.dropdown-menu {
  background-color: #{$color-bg};
}

.dropdown-item {
  color: #{$text-color};
}

.card {
  background-color: #{$color-fg} !important;
}

.navbar-space {
  height: $navbar-height;
}

$contentHeight: calc(100vh - $navbar-height);

html, body, #app {
  height: 100vh;
  overflow-x: hidden;
}

.main-content {
  min-height: #{$contentHeight};
}

.container-fluid {
  padding: 0;
}

.card.card-game {
  background-color: #fff0;
  position: relative;
  padding-bottom: 56.2%;

  & img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .card-img-overlay.overlay-transparent-bottom {
    opacity: .95;
    padding: 0.7rem;
    top: auto;
    font-size: 0.8rem;
    border-radius: 0;
  }

  & .card-img-overlay.overlay-transparent-bottom:hover {
    opacity: .1;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
  }

  & .card-img-overlay.card-badge {
    padding: 0.3rem;
    padding-top: 0;
    left: auto;
    bottom: auto;
  }
}

.watch-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: gray;

  &>div {
    height: 100%;
    background-color: red;
  }
}

.col-card {
  padding: 5px;
  min-width: 20%; // 5 cards in a row with col-xl-2
}

.card .show-more {
  background-color: #{$color-bg-light};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #{$text-color};
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  &:hover {
    text-decoration: none;
    background-color: lighten($color-bg-light, 10%);
  }
}

footer.page-footer a {
  color: #fff;
}

footer .footer-copyright a {
  text-decoration: underline;
}

h1>.badge,
h2>.badge,
h3>.badge {
  vertical-align: middle;
  font-size: 0.8rem;
  margin-top: -0.4em;
}

.modal {
  & button.close {
    color: $text-color;
  }
}

/*
 * Horizontal separator
 * Source: https://stackoverflow.com/a/36159798
 */

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px !important;
}

.darkmode--activated .hr-sect {
  color: rgba(255, 255, 255, 0.35);
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.darkmode--activated .hr-sect:before,
.darkmode--activated .hr-sect:after {
  background: rgba(255, 255, 255, 0.35);
}

.badge a {
  text-decoration: none;
  color: inherit;
}

.badge-row {
  margin: 0.3em;
  margin-top: auto;
  margin-bottom: 1.4em;
  margin-left: 1em;
}

.visited {
  color: purple;
}

/*
 * Date Picker
 */

.react-date-picker__calendar {
  z-index: 1000 !important;
}

.react-date-picker__calendar-button {
  display: none;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker.form-control {
  padding: 0;

  & > .react-date-picker__wrapper {
    height: 100%;
    border: none;
  }

  & input {
    color: #{$text-color};
  }

  & span {
    color: #{$input-color-placeholder};
  }
}

.react-date-picker__inputGroup {
  text-align: center;
}

.bg-lightgreen {
  background-color: lightgreen;
}

.bg-lightcoral {
  background-color: lightcoral;
}

/*
 * Quick Search
 */

.rbt-menu.dropdown-menu {
  padding: 0;

  & .dropdown-item {
    white-space: inherit;
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
  }
}

/*
 * Timecodes
 */

.timecodes-list.list-group {
  font-size: 0.8rem;
  border-radius: 0;

  button.list-group-item-action {
    border-bottom: none;
  }

  & .list-group-item {
    padding: 0.25rem;

    & > svg {
      align-self: center;
      padding-right: 0.25rem;
    }
  }

  & > .list-group {
    margin-left: 0.2rem;
  }

  & > .list-group-item {
    border-radius: 0;

    & a.visited {
      color: #e0e;
    }
  }

  $timecodeColors:  #980740, #d14053, #f06c44, #f8ae65, #fadf90;
  $i: 0;

  @each $color in $timecodeColors {
    & .list-group.timecodes-nested-#{$i} {
      &:not(:last-child) > :last-child {
        border-bottom: none;
      }

      & .list-group-item {
        box-shadow: -0.2rem 0 $color;
      }
    }

    $i: $i + 1;
  }
}

/*
 * Playlist
 */

.border-none {
  border: none !important;
}

.separator-v > * {
  border-top: none !important;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-color !important;
    margin-bottom: 1px;
  }

  &:last-of-type {
    border-bottom: none !important;
  }
}

.separator-h > * {
  border-left: none !important;

  &:not(:last-of-type) {
    border-right: 1px solid $border-color !important;
    margin-right: 1px;
  }

  &:last-of-type {
    border-right: none !important;
  }
}

.playlist-header .btn {
  border-radius: 0;
}

.playlist-streams {
  font-size: 0.8rem;

  & .playlist-streams-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem;
  }
}

/*
 * Chat
 */

.btn-link {
  color: #{$text-color} !important;
  margin: -0.3rem;
  
  &:focus {
    box-shadow: none !important;
  }
}

.react-auto-scroll, .scrollable { 
  height: 100%;

  &.scrollable {
    overflow-y: auto;

    &.scrollable-hidden {
      scrollbar-width: none;
    }

    & .scrollable-content {
      width: 100%;
    }
  }

  & > .react-auto-scroll__option {
    display: none;
  }

  & > :first-child {
    height: 100% !important;
    position: relative;
    height: auto;

    & > * {
      position: absolute;
    }
  }
}

.chat-messages-list.list-group {
  font-size: 0.8rem;
  width: 100%;

  & .list-group-item {
    background-color: inherit;
    padding: 0.25rem;
    overflow-wrap: anywhere; // by @AriesAlex

    & span.username {
      font-weight: 700;
    }

    & .emote {
      height: 24px;
    }

    & .emote-placeholder {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  & .list-group-item:last-child {
    border-bottom: inherit;
    margin-bottom: 1px;
  }
}

.dropdown-toggle-hidden::after {
  content: none;
}

.chat-settings-item {
  padding: 0.25rem 1rem 0.25rem 2rem;
  color: #{$text-color};
	clear: both;
	white-space: nowrap;
}

.form-control-sm.btn {
  // override .btn with .form-control-sm
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

/*
 * Segment Player
 */

.share-popover {
  & .popover-body {
    padding: 0.6rem 0.6rem;
  }

  & .form-check {
    display: flex;
    align-items: center;

    & .form-check-input {
      margin-top: 0;
      margin-right: 0.25rem;
    }
  }

  & .time-selector {
    width: unset;
  }
}

.spinner-border-xl {
  width: 10rem;
  height: 10rem;
}

.stream-description {
  padding: 1rem;
  color: #{$text-color};

  & .list-group-item {
    padding-left: 0;
    border: none;
  }
}

.btn .led {
  position: absolute;
  display: inline-block;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 100%;
}

.btn .spinner-border {
  vertical-align: middle;
}

.sidebar-content {
  background-color: #{$color-bg-light};
  color: #{$text-color};
  display: flex;
  flex-direction: column;

  & > .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: inherit;
  }

  & .list-group {
    border-radius: 0;
  }

  .nav-tabs {
    display: flex;
    flex-direction: row;
    border-color: $border-color;

    & .nav-item {
      flex: 1 0 auto;

      & .nav-link {
        width: 100%;
        height: 2rem;
        padding: 0.2rem;
        color: $text-color;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;

        border-radius: 0;
        border-color: $border-color;

        @each $direction in top left bottom {
          border-#{$direction}: none;
        }
      }

      &:last-of-type .nav-link {
        border-right: none;
      }
    }
  }

  & .tab-content {
    flex: 1 1 0;

    & > .tab-pane.active {
      height: 100%;
      display: flex;
      flex-direction: column;

      & .sidebar-header {
        display: none;
      }
    }
  }

  & .sidebar-row {
    width: 100%;
    padding: 0.3rem;
    color: #{$text-color};
    background-color: #{$color-bg-light};
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .sidebar-row-fixed {
    @extend .sidebar-row;
    height: 2rem;
  }

  & .sidebar-row-overlay {
    @extend .sidebar-row-fixed;

    position: relative;
    margin-bottom: -2rem;
    background-color: rgba(0, 0, 0, 0);
  }

  & .sidebar-header {
    @extend .sidebar-row-fixed;

    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    justify-content: center;

    border-bottom: 1px solid #{$border-color};
  }

  *:not(.sidebar-row-overlay) + .sidebar-header {
    border-top: 1px solid #{$border-color};
  }
}

.scroll-outer {
  display: flex;
  flex-direction: column;
}

@mixin col-sidebar($width: 240px) {
  @extend .sidebar-content;

  flex: 0 0 $width;
  height: $contentHeight;
  position: sticky;
  top: $navbar-height;
  z-index: 50;

  & > .collapsed-content {
    display: none;
  }

  &.collapsed {
    max-width: calc(2rem + 1px);
    border-right: 1px solid $border-color;

    & > .collapsed-content {
      width: $contentHeight;
      height: 100%;

      display: flex;
      flex-direction: row;

      transform-origin: top left;
      transform: rotate(90deg) translateY(-2rem);

      & > .sidebar-header {
        width: unset;
        flex-grow: 1;
        transform: rotate(180deg);
        border: none;
      }
    }

    & > .content {
      position: absolute;
      width: $width;
      height: 100%;
      top: 0;
      left: -$width;
      transition: left 0.35s ease;
      z-index: 50;
    }

    &:focus-within, &:hover {
      & > .content {
        left: 0;
      }
    }
  }
}

.sidebar-placeholder {
  width: 2rem;
}

.col-sidebar-narrow {
  @include col-sidebar();
}

.col-sidebar-wide {
  @include col-sidebar();

  background-color: #{$color-bg};

  @media (min-width: 992px) {
    flex: 0 0 340px;
  }
}

.btn {
  svg + span, span + svg {
    padding-left: 0.25rem;
  }
}

.d-xxl-block {
  @media(min-width: 1400px) {
    display: block !important;
  }
}

.player-controls {
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
  background-color: #{$color-bg-light};

  & .label {
    color: #fff;
    align-self: center;
  }
}

.plyr__control__custom {
  line-height: 0 !important;
  opacity: 0.5;

  &.plyr__control--pressed {
    opacity: 1;
  }
}

.plyr-row {
  background-color: #000;
  
  > .plyr:not(:fullscreen):not(.plyr--fullscreen-fallback) {
    height: 100%;
    margin: auto;
  }
}

.plyr .plyr-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2; // above video (0), poster (1) and controls (3)

  & .chat-overlay {
    pointer-events: all;
    display: flex !important;
    flex-direction: column;

    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 0.5rem;

    &:hover, &:active {
      border: 5px solid #00b3ff;
    }

    & .chat-messages-list {
      pointer-events: all;
      border: none;

      & > .list-group-item {
        border: none;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 1rem;
        text-shadow:
          0px 0px 4px black,
          0px 0px 4px black,
          0px 0px 8px black,
          0px 0px 8px black;
      }
    }
  }
}

.tooltip {
  top: 0;
}

.plyr:fullscreen iframe, .plyr--fullscreen-fallback iframe {
  pointer-events: none;
}

.plyr--youtube {
  &:not(:fullscreen):not(.plyr--fullscreen-fallback) {
    & .plyr__controls, .plyr__control[data-plyr="play"] {
      display: none;
    }
  }

  & .plyr__poster {
    display: none;
  }
}

.stream-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 2rem;
  align-items: center;
  padding: 0 0.3rem;
  background-color: #{$color-bg-light};
  color: #{$text-color};

  & > * {
    margin-right: 0.5rem;
  }

  & a {
    color: #{$text-color};
  }
}

/*
 * Interactive search
 */

@media (max-width: '991px') { // keep gutters with container-fluid
  .search {
    margin-left: 0;
    margin-right: 0;
  }
}

.search-control {
  @include col-sidebar(300px);

  background-color: $color-bg-light;
  padding-left: 0;
  padding-right: 0;

  & .content {
    padding-top: 1rem;
  }

  & .content > * {
    &:not(.sidebar-header) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    margin-bottom: 1rem;
  }
}